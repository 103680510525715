// src/post/domain/service/PostService.ts

export const $FEATURED_ITEMS: number = 3;
export const $PER_PAGE_ITEMS: number = 9;

export const $RELATED_NEWS_ITEMS: number = 3;

export const $LIVE_CHRONICLE_POST_CHECK: number = 120000; // 2 minutes

export class PostService {
  static getCategoryIds(context: string): number[] | null {
    const kingsCategoryIds = [16, 21, 8, 19, 24, 25, 26, 27];
    const queensCategoryIds = [17, 22, 18, 23, 28, 29, 30, 31];

    switch (context) {
      case "kings":
        return kingsCategoryIds;
      case "queens":
        return queensCategoryIds;
      default:
        return null;
    }
  }

  static isCategory(context: string, categoryId: number): boolean {
    const categoryIds = PostService.getCategoryIds(context);
    return categoryIds ? categoryIds.includes(categoryId) : false;
  }
}
